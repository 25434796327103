import React from "react";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import GalleryLib from "react-photo-gallery";
import Lightbox from "react-images";

const Gallery = class extends React.Component {
  state = { currentImage: 0, lightboxIsOpen: false };

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  render() {
    return (
      <>
        <h2>Gallery</h2>
        <GalleryLib photos={PHOTO_SET} onClick={this.openLightbox} />
        <Lightbox
          images={PHOTO_SET}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </>
    );
  }
};

const PHOTO_SET = [
  {
    src: "/assets/gallery/pic1.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic2.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic3.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic4.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic5.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic6.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic7.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic8.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/pic9.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/gallery/poster5.jpg",
    width: 1.3,
    height: 2
  }
];

export default Gallery;
