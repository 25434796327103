/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const styles = css`
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      margin: 0 10px;

      img {
        border: 1px #000 solid;
        font-weight: 0;
      }

      a {
        img {
          opacity: 0.8;
          transition: opacity ease-in-out 0.5s;
        }
        p {
          text-align: center;
          text-decoration: underline;
        }
      }
      a:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
`;

const Videos = () => (
  <div css={styles}>
    <h2>Music videos</h2>
    <div>
      <ul>
        <li>
          <a
            href="https://youtu.be/l3AecO9M6qs"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src="/assets/videos/thebackdoor.png" alt="The Backdoor" />
            <p>The Backdoor</p>
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/VhOt41ekRV4"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src="/assets/videos/suicide.png" alt="Suicide" />
            <p>Suicide</p>
          </a>
        </li>
      </ul>
    </div>
    <h2>Live videos</h2>
    <div>
      <ul>
        <li>
          <a
            href="https://youtu.be/kiiGpCo6pW4"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src="/assets/videos/godinyou.png" alt="God in You" />
            <p>God in you</p>
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/Mz9M_vNyDiM"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src="/assets/videos/seenitall.png" alt="Seen it All" />
            <p>Seen it All</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default Videos;
