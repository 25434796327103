/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const styles = css`
  padding-top: 20px;
  padding-bottom: 100px;
`;

const Section = ({ children }) => <section css={styles}>{children}</section>;

export default Section;
