/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Element } from "react-scroll";

import Section from "./Section";

// routes
import Home from "./routes/Home";
import Videos from "./routes/Videos";
import Music from "./routes/Music";
import Gallery from "./routes/Gallery";
import About from "./routes/About";

const styles = css`
  flex: 1;
  padding-top: 140px;

  section {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const Main = () => (
  <main css={styles}>
    <Element
      name="container"
      id="main"
      style={{ height: "100vh", overflowX: "scroll" }}
    >
      <Element name="home">
        <Section>
          <Home />
        </Section>
      </Element>
      <Element name="music">
        <Section>
          <Music />
        </Section>
      </Element>
      <Element name="videos">
        <Section>
          <Videos />
        </Section>
      </Element>
      <Element name="gallery">
        <Section>
          <Gallery />
        </Section>
      </Element>
      <Element name="about">
        <Section>
          <About />
        </Section>
      </Element>
    </Element>
  </main>
);

export default Main;
