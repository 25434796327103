import React from "react";
/** @jsx jsx */
import { Global, jsx, css } from "@emotion/core";
import GoogleFontLoader from "react-google-font-loader";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

const container = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

class App extends React.Component {
  render() {
    return (
      <>
        <GoogleFontLoader fonts={[{ font: "Merienda One", weights: [400] }]} />
        <Global
          styles={css`
            body {
              background: url("/assets/theone.png") top right no-repeat;
              background-size: cover;
              background-attachment: fixed;
              font-family: "Merienda One", serif;
            }

            body,
            a {
              color: #bbbab7;
              transition: color ease-in-out 0.5s;
              text-decoration: none;
            }
            a:hover {
              color: #fff;
            }
            .active {
              color: red;
            }

            h2 {
              font-size: 0.875rem;
              font-weight: normal;
              text-transform: uppercase;
              text-align: center;
            }

            h3 {
              font-size: 0.75rem;
              font-weight: normal;
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
            }

            p {
              font-size: 0.75rem;
            }
          `}
        />
        <div css={container}>
          <Header />
          <Main />
          <Footer />
        </div>
      </>
    );
  }
}

export default App;
