/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const styles = css`
  height: 100vh;
  p {
    line-height: 1.8;
  }
`;

const About = () => (
  <div css={styles}>
    <h2>About</h2>
    <p>
      Anders “Andesh” Ek was born 1986 in Stockholm, Sweden. He started learning
      the guitar in his early teens and formed his first band, the experimental
      rock/punk band “Koboldo” at the age of 15. Since then he’s been the
      creative force behind many bands and music collaborations ranging all the
      way from pop and hiphop to the extreme metal acts “iCitizen” and “Årsta”.{" "}
    </p>
    <p>
      In 2015 he left his hometown and relocated to Europes cultural melting
      pot, Berlin, to further develope his guitar playing and music producing.
      Leaving most of his musical collaborators behind he formed his solo act,
      simply called “Andesh”. The name is the phonetical pronounciation of
      Anders in Swedish. Working with live looping and an acoustic guitar
      drenched in effects he developed a dark and mystical yet beautiful sound
      inspired by a cross-over of the music of the deep southern blues men and
      the drones and mantras of traditional music of the east.
    </p>
  </div>
);

export default About;
