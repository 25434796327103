/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const container = css`
  position: relative;
  margin-top: 40px;
`;

const mute = css`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  background: #000;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 999;
  opacity: 0.5;
  transition: opacity ease-in-out 0.3s;
  &:hover {
    opacity: 0.9;
  }
`;

const Home = () => (
  <div css={container}>
    <img
      css={mute}
      src="/assets/mute_audio_white.svg"
      alt="Un-mute"
      id="mute-button"
      onClick={ev => {
        ev.target.classList.toggle("muted");
        document.getElementById(
          "video-player"
        ).muted = !document.getElementById("video-player").muted;
        document.getElementById("mute-button").src = !document.getElementById(
          "video-player"
        ).muted
          ? "/assets/play_audio_white.svg"
          : "/assets/mute_audio_white.svg";
      }}
    />
    <video width="100%" id="video-player" playsInline autoPlay muted loop>
      <source src="/assets/seen_it_all_lowres.mp4" type="video/mp4" />
      Your browser doesn't support HTML5 video tag.
    </video>
  </div>
);

export default Home;
