/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const styles = css`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px 10px;

  a {
    font-size: 0.75rem;
  }

  section {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fb {
    background: url("/assets/fejjan.png") no-repeat;
    padding: 30px 0 0 30px;
  }

  .insta {
    background: url("/assets/insta.png") no-repeat;
    padding: 30px 0 0 30px;
  }

  .share {
    display: flex;
    align-items: center;

    > span {
      font-size: 0.75em;
    }
  }

  .icons {
    display: flex;
    flex-direction: column;
  }
`;

const Footer = () => (
  <footer css={styles}>
    <section>
      <a href="mailto:andeshmusic@gmail.com">andeshmusic@gmail.com</a>
      <div className="share">
        <div className="icons">
          <a className="fb" href="http://facebook.com/andeshmusic" />
          <span className="insta" />
        </div>
        <span>/andeshmusic</span>
      </div>
    </section>
  </footer>
);

export default Footer;
