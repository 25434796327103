/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const title = css`
  text-transform: uppercase;
`;

const container = css`
  text-align: center;
`;

const record = css`
  display: flex;
  width: 100%;
  position: relative;
  text-align: center;

  img {
    margin: 0 auto;
  }

  div {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const list = css`
  font-size: 0.66rem;
  line-height: 1.4rem;
  text-decoration: underline;
`;

const Music = () => (
  <div css={container}>
    <h2 css={title}>Digital releases:</h2>
    <h3>Source - Ep / 2018</h3>
    <div css={record}>
      <img src="/assets/music_source.png" alt="Andesh - Source" />
      <div>
        <h2>Listen here</h2>
        <ul css={list}>
          <li>
            <a
              href="https://open.spotify.com/album/6uFPEf0WFube2b7XAdnd2c?si=OciDIJvuQ_qA6SSC90qniA"
              rel="noopener noreferrer"
              target="_blank"
            >
              Spotify
            </a>
          </li>
          <li>
            <a
              href="https://itunes.apple.com/se/album/source-ep/1434348858?l=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              iTunes
            </a>
          </li>
          <li>
            <a
              href="https://soundcloud.com/andeshmusic/sets/source-ep"
              rel="noopener noreferrer"
              target="_blank"
            >
              Soundcloud
            </a>
          </li>
        </ul>
      </div>
    </div>
    <h3>Suicide - Single / 2018</h3>
    <div css={record}>
      <img src="/assets/Andesh_Suicide_pic.png" alt="Andesh - Suicide" />
      <div>
        <h2>Listen here</h2>
        <ul css={list}>
          <li>
            <a
              href="https://open.spotify.com/album/2F5ld95K4MpoYZlOkbziTX?si=GUdTfHP8SAWt-120g2nPHQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              Spotify
            </a>
          </li>
          <li>
            <a
              href="https://itunes.apple.com/se/album/suicide-single/1406499500?l=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              iTunes
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Music;
